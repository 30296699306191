
.container {
  border: 2px solid #ccc; /* Borde para el contenedor */
  border-radius: 10px; /* Redondear los bordes */
  padding: 25px; /* Espaciado interno */
  max-width: 400px; /* Ancho máximo */
  margin: 0 auto; /* Centrar en la pantalla */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra */
  background-color: #fff; /* Color de fondo */
}

@media (min-width: 768px) {
  .logo {
    max-width: 200px; /* Cambia el tamaño de la imagen para pantallas más grandes */
  }
}



.button-container {
  display: flex;
  justify-content: center; /* Centrar horizontalmente los elementos */
}

.button {
  margin-top: 20px;
  margin-right: 10px; /* Agrega espacio entre los botones */
  padding: 10px 20px;
  font-size: 20px;
  border: none;
  border-radius: 4px;
  background-color: #B92026;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:last-child {
  margin-right: 0; /* Elimina el margen derecho del último botón */
}

.footer {
  background-color: transparent; /* Establece el color de fondo del footer como transparente */
  padding: 10px; /* Ajusta el padding según sea necesario */
  position: sticky; /* Posiciona el footer de forma relativa */
  left: 0; /* Alinea el footer a la izquierda */
  bottom: 0; /* Alinea el footer en la parte inferior */
  width: 100%; /* Establece el ancho del footer al 100% del ancho de la ventana */
  text-align: right; /* Alinea el texto a la derecha */
  border-top: 1px solid #ccc; /* Añade una línea en la parte superior con color gris */
}



.mas-info-button {
  display: inline-block;
  margin-top: 20px;
  margin-left: 0; /* Elimina el margen izquierdo */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #B92026;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  float: left; /* Alinea el botón a la izquierda */
}

.mas-info-button:hover {
  background-color: #f78da7;
}

.masInfoButtonContainer {
  text-align: left;
  margin-top: 10px; /* Ajusta el margen superior según tu diseño */
}

.mas-info-button:hover {
  background-color: #f78da7;
}


