* {
    box-sizing: border-box;
  }
  
  body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 4px;
    border: 1px solid #ddd;
    font-size: max(1vw, 12px); /* Tamaño de fuente relativo al ancho de la ventana, mínimo de 12px */
  }
  
  th {
    background-color: #555;
    color: #fff;
  }
  
  td {
    text-align: center;
  }
  
  .table-responsive {
    max-width: 100%;
    overflow-x: auto;
  }
  
  .boton-especie {
    background-color:fff
     #c20909;
    color: #c20909;
    padding: max(0.5vw, 5px) max(1vw, 10px); /* Padding relativo al ancho de la ventana, mínimo de 5px y 10px */
    
    border-radius: 5px;
    font-size: max(1vw, 12px); /* Tamaño de fuente relativo al ancho de la ventana, mínimo de 12px */
    cursor: pointer;
    margin-right: 2%;
  }
  .boton-actualizar,
  .boton-volver,
  .boton-exportar {
    background-color: #c20909;
    color: #fff;
    padding: max(0.5vw, 5px) max(1vw, 10px); /* Padding relativo al ancho de la ventana, mínimo de 5px y 10px */
    border: none;
    border-radius: 5px;
    font-size: max(1vw, 12px); /* Tamaño de fuente relativo al ancho de la ventana, mínimo de 12px */
    cursor: pointer;
    margin-right:3%; /* Espaciado entre botones */
  }
  
  .boton-especie:hover,
  .boton-actualizar:hover,
  .boton-volver:hover,
  .boton-exportar:hover {
    opacity: 0.6; /* Cambia la opacidad al pasar el ratón */
  }
  
  .alineado-izquierda {
    text-align: left;
  }
  
  @media screen and (max-width: 768px) {
    .table-responsive {
      width: 100%;
      margin-bottom: 16px;
    }
    .table-responsive > table {
      width: 100%;
    }
    .table-responsive > table > thead > tr > th,
    .table-responsive > table > tbody > tr > th,
    .table-responsive > table > tfoot > tr > th,
    .table-responsive > table > thead > tr > td,
    .table-responsive > table > tbody > tr > td,
    .table-responsive > table > tfoot > tr > td {
      display: block;
      width: 100%;
      max-width: 100%;
    }
    .table-responsive > table > thead > tr > th {
      border-bottom: 1px solid #ddd;
    }
    .table-responsive > table > tbody > tr > td {
      border-bottom: 1px solid #ddd;
    }
  
    /* Redefinir tamaño de fuente y padding para botones */
    .boton-especie,
    .boton-actualizar,
    .boton-volver,
    .boton-exportar {
      padding: 5px 10px; /* Tamaño de padding fijo para botones */
      font-size: 12px; /* Tamaño de fuente fijo para botones */
    }
  }
  