.container {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 20px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-display: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dataContainer {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  padding-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  width: 100%;
  max-width: 550px;
  background-color: #fff;
}

.button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  background-color: #B92026;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #f78da7
}

.table-container {
  width: 100%;
  max-width: 800px;
  margin: 5px auto;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container th,
.table-container td {
  padding: 4px 5px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #333;
}

.table-container tbody tr:hover {
  background-color: #da12128e;
}

.table-container td:nth-child(2) {
  max-width: 40%;
}
.right-align {
  text-align: right !important;
}
.center-align {
  text-align: center !important;
  padding: 5px 5px 5px 5px ;
  
}
.masInfoButtonContainer {
  text-align: left;
  margin-top: 10px; /* Ajusta el margen superior según tu diseño */
}

.dataAndDetailContainer {
  display: flex;
  justify-content: space-between;
}

.data {
  width: 45%; /* Ajusta el ancho según tus necesidades */
}

.detail {
  width: 45%; /* Ajusta el ancho según tus necesidades */
}

.button-detail {
  font-size: 12px;
  padding: 4px 8px;
  background-color: #B92026; /* Color de fondo azul */
  color: white; /* Color de texto blanco */
  margin-left: 15px; /* Espacio a la derecha del texto */
  margin-bottom: 8px; /* Espacio por debajo del texto */
  border: none; /* Sin borde */
  cursor: pointer; /* Cursor de puntero al pasar el ratón */
  transition: background-color 0.3s; /* Transición de color de fondo al pasar el ratón */
  border-radius: 4px;
}

.button-detail:hover {
  background-color: #f78da7; /* Color de fondo azul más oscuro al pasar el ratón */
}

.text-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 1.5;
  color: #212529;
}
