header {
  display: flex;
  justify-content: space-between; /* Alinear elementos horizontalmente */
  align-items: center; /* Centrar verticalmente */
}

.logo {
  max-width: 2100%;
  height: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: auto; /* Empuja la imagen hacia la derecha */
  margin-right: auto; /* Empuja la imagen hacia la izquierda */
}

.titulo-carni {
  font-size: 20px;
  text-align: right;
}
 .pie-de-tabla {
    font-weight: bold;
    background-color: #f2f2f2;
  }

