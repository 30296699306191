.front-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.tile {
  flex: 0 0 calc(50% - 20px);
  margin: 10px;
  padding: 10px 20px;
  font-size: 20px;
  border: 2px solid #B92026;
  border-radius: 4px;
  background-color: #fff;
  color: #070707;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.tile:hover {
  background-color: #B92026;
  color: #fff;
  border-color: #B92026;
  text-decoration: none;
  transform: translateY(-5px);  
}

.tile h2, .tile p {
  margin: 0;
}

@media (max-width: 412px) {
  .tile {
    flex: 0 0 calc(100% - 20px);
  }
}

